import React from "react";
import { motion } from "framer-motion";

function StepThree({ formVariants }) {
  return (
    <motion.div
      variants={formVariants}
      initial="initial"
      animate="enter"
      exit="exit"
      key="finalForm"
      className="bg-inherit shadow-none border-none mt-16 lg:mt-28 text-primary-11 text-center text-2xl p-8"
    >
      <h1>تم التسجيل بنجاح, سوف يتم مراجعة البيانات والموافقة على الحساب.</h1>
    </motion.div>
  );
}

export default StepThree;
