import axios from "axios";

const baseUrl = process.env.PROD_API_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default { axiosInstance };
