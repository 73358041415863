import React from "react";
import ReactSelect from "react-select";

import { cn } from "@utils/cn";
import { Button } from "@components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { Input } from "@components/ui/input";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form";
import { motion } from "framer-motion";

import { days, provinceNames, specialityNames } from "@data/staticOptions";
import { LuTrash } from "react-icons/lu";
import LocationSelector from "../LocationSelector";
import { Textarea } from "@components/ui/textarea";
import { PiImageThin } from "react-icons/pi";

function StepTwo({
  form,
  formVariants,
  phoneNumbers,
  removePhoneNumber,
  appendPhoneNumber,
  appendInfo,
  removeInfo,
  infos,
  handlePositionChange,
  file,
  getRootProps,
  getInputProps,
  isDragActive,
  isPending,
}) {
  return (
    <motion.div
      variants={formVariants}
      initial="initial"
      animate="enter"
      exit="exit"
      key="passwordForm"
    >
      <Card className="bg-inherit shadow-none border-none mt-16 lg:mt-28">
        <CardHeader className="p-6 pb-2 space-y-1 text-center">
          <CardTitle className="text-2xl text-primary-11">
            سوف يتمكن مستخدمي برنامج صحتي من الاطلاع على المعلومات
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-4 lg:grid lg:gap-4 pt-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                {/* <FormLabel>الاسم</FormLabel> */}
                <FormControl>
                  <Input
                    placeholder="الاسم"
                    {...field}
                    className="border-white bg-white bg-opacity-60 rounded-xl text-primary-10 focus:outline-none"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {form.watch("category_id") == 1 && (
            <>
              <div className="text-justify text-lg font-semibold flex gap-4">
                <button
                  className={cn(
                    "bg-white bg-opacity-60 p-3 rounded-xl px-8 hover:bg-opacity-30 transition ease-out duration-300 focus:outline-none",
                    form.watch("gender_id") == 2 && "bg-primary-7"
                  )}
                  onClick={() => form.setValue("gender_id", 2)}
                  type="button"
                >
                  ذكر
                </button>
                <button
                  className={cn(
                    "bg-white bg-opacity-60 p-3 rounded-xl px-8 hover:bg-opacity-30 transition ease-out duration-300 focus:outline-none",
                    form.watch("gender_id") == 1 && "bg-primary-7"
                  )}
                  type="button"
                  onClick={() => form.setValue("gender_id", 1)}
                >
                  انثى
                </button>
              </div>
              <FormField
                control={form.control}
                name="specialities"
                render={({ field }) => (
                  <FormItem>
                    {/* <FormLabel>التخصص</FormLabel> */}
                    <FormControl>
                      <ReactSelect
                        // defaultValue={selectedOption}
                        // onChange={setSelectedOption}
                        options={specialityNames}
                        {...field}
                        isMulti
                        placeholder="اختر التخصص"
                        value={specialityNames.filter((c) =>
                          form.watch("specialities").includes(c.id)
                        )}
                        onChange={(selected, action) => {
                          if (action.action === "clear") {
                            form.setValue("specialities", []);
                          } else {
                            form.setValue(
                              "specialities",
                              selected.map((speciality) => speciality.id)
                            );
                          }
                        }}
                        isClearable
                        getOptionLabel={(option) =>
                          option.__isNew__ ? option.label : option.name
                        }
                        getOptionValue={(option) =>
                          option.__isNew__ ? option.value : option.id
                        }
                        closeMenuOnSelect={false}
                        classNamePrefix="react-select"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          <div className="flex flex-wrap flex-col gap-2">
            {phoneNumbers.map((item, index) => (
              <div key={item.id} className="flex flex-row items-center gap-2">
                <FormField
                  {...form.register(`phone_numbers.${index}`)}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      {/* <FormLabel>رقم الهاتف</FormLabel> */}
                      <FormControl>
                        <Input
                          {...field}
                          dir="rtl"
                          className="border-white bg-white bg-opacity-60 rounded-xl text-primary-10"
                          placeholder="رقم الهاتف"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {phoneNumbers.length > 1 && index != 0 && (
                  <Button
                    type="button"
                    onClick={() => removePhoneNumber(index)}
                    className=""
                  >
                    <LuTrash size={20} />
                  </Button>
                )}
              </div>
            ))}
          </div>
          <div
            type="button"
            onClick={() => appendPhoneNumber("")}
            className="cursor-pointer text-white transition d-300ms py-2 px-2 rounded-xl justify-self-start hover:text-slate-100 hover:bg-slate-700"
          >
            اضافة رقم هاتف اخر
          </div>
          <div className="flex flex-wrap flex-col">
            {infos.map((info, index) => (
              <div key={info.id} className="flex flex-col gap-3">
                {/* <Input /> */}

                <FormField
                  {...form.register(`infos.${index}.address`)}
                  render={({ field }) => (
                    <FormItem>
                      {/* <FormLabel>العنوان</FormLabel> */}
                      <FormControl>
                        <Input
                          placeholder="العنوان"
                          {...field}
                          className="border-white bg-white bg-opacity-60 rounded-xl text-primary-10"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <LocationSelector
                  index={index}
                  position={{
                    latitude: form.watch(`infos.${index}.latitude`),
                    longitude: form.watch(`infos.${index}.longitude`),
                  }}
                  handlePositionChange={handlePositionChange}
                />
                <FormField
                  {...form.register(`infos.${index}.province_id`)}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      {/* <FormLabel>المحافظة</FormLabel> */}
                      <FormControl>
                        <ReactSelect
                          // defaultValue={selectedOption}
                          // onChange={setSelectedOption}
                          {...field}
                          options={provinceNames}
                          {...form.register(`infos.${index}.province_id`)}
                          placeholder="اختر المحافظة"
                          isClearable
                          getOptionLabel={(option) =>
                            option.__isNew__ ? option.label : option.name
                          }
                          getOptionValue={(option) =>
                            option.__isNew__ ? option.value : option.id
                          }
                          value={provinceNames.find(
                            (c) =>
                              c.id === form.watch(`infos.${index}.province_id`)
                          )}
                          onChange={(val) =>
                            form.setValue(`infos.${index}.province_id`, val.id)
                          }
                          classNamePrefix="react-select"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  {...form.register(`infos.${index}.days`)}
                  render={({ field }) => (
                    <FormItem className="w-full">
                      {/* <FormLabel>ايام التواجد</FormLabel> */}
                      <FormControl>
                        <ReactSelect
                          // defaultValue={selectedOption}
                          // onChange={setSelectedOption}
                          options={[{ name: "كل الايام", id: "all" }, ...days]}
                          {...field}
                          value={days.filter((day) =>
                            form.watch(`infos.${index}.days`).includes(day.id)
                          )}
                          onChange={(selected, action) => {
                            console.log(selected);
                            if (action.action === "clear") {
                              form.setValue(`infos.${index}.days`, []);
                            } else {
                              if (
                                selected.find((option) => option.id === "all")
                              ) {
                                form.setValue(
                                  `infos.${index}.days`,
                                  days.map((day) => day.id)
                                );
                              } else {
                                form.setValue(
                                  `infos.${index}.days`,
                                  selected.map((day) => day.id)
                                );
                              }
                            }
                          }}
                          placeholder="اختر ايام العمل"
                          isClearable
                          isMulti
                          getOptionLabel={(option) =>
                            option.__isNew__ ? option.label : option.name
                          }
                          getOptionValue={(option) =>
                            option.__isNew__ ? option.value : option.id
                          }
                          closeMenuOnSelect={false}
                          classNamePrefix="react-select"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className=" bg-white bg-opacity-60 px-6 py-5 rounded-xl mx-2">
                  <h2 className="text-xl font-semibold mb-5">وقت العمل</h2>
                  <div className="flex justify-evenly gap-4">
                    <FormField
                      {...form.register(`infos.${index}.from_time`)}
                      render={({ field }) => (
                        <FormItem className="flex flex-row gap-2 justify-center items-center">
                          <FormLabel className="text-primary-9">من</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="من"
                              type="time"
                              {...field}
                              className="border-white bg-white bg-opacity-60 rounded-xl text-primary-10"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      {...form.register(`infos.${index}.to_time`)}
                      render={({ field }) => (
                        <FormItem className="flex flex-row gap-2 justify-center items-center">
                          <FormLabel className="text-primary-9">الى</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="الى"
                              type="time"
                              className="border-white bg-white bg-opacity-60 rounded-xl text-primary-10"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                {infos.length > 1 && index != 0 && (
                  <Button type="button" onClick={() => removeInfo(index)}>
                    <LuTrash size={20} />
                  </Button>
                )}
              </div>
            ))}
          </div>
          <Button
            type="button"
            variant="outline"
            className="text-white outline-white hover:bg-slate-700"
            onClick={() =>
              appendInfo({
                province_id: null,
                address: null,
                longitude: null,
                latitude: null,
                from_day: null,
                to_day: null,
                days: [],
                from_time: null,
                to_time: null,
              })
            }
          >
            اضافة مكان عمل اخر
          </Button>
          <FormField
            control={form.control}
            name="details"
            render={({ field }) => (
              <FormItem>
                {/* <FormLabel>الوصف</FormLabel> */}
                <FormControl>
                  <Textarea
                    placeholder="الوصف"
                    {...field}
                    rows="5"
                    className="border-white bg-white bg-opacity-60 rounded-xl text-primary-10"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-2">
            <div
              className="border-dashed border-2 border-gray-300 rounded p-4 text-center flex gap-4 justify-center"
              {...getRootProps()}
            >
              <PiImageThin className="size-8 text-primary-10 font-bold" />
              <div className="flex flex-col">
                <input {...getInputProps()} accept="image/png, image/jpeg" />
                <p>ارفع صورتك هنا ...</p>
              </div>
            </div>
            {file && (
              <img
                src={file?.preview}
                className="w-full h-[200px] md:h-[400px] rounded-lg mb-2 border-1 border-gray-300"
                alt={file?.name}
              />
            )}
          </div>
          <Button
            type="submit"
            className="bg-primary-7 text-white"
            disabled={isPending}
          >
            تسجيل
          </Button>
        </CardContent>
      </Card>
      <p className="px-8 pt-2 pb-8 text-center text-sm">
        بالضغط على التسجيل فانت توافق على{" "}
        <a
          href="https://health-360.co/terms"
          className="underline underline-offset-4 hover:text-primary"
        >
          احكام الخدمة
        </a>{" "}
        و{" "}
        <a
          href="https://health-360.co/privacy"
          className="underline underline-offset-4 hover:text-primary"
        >
          شروط الخصوصية
        </a>
        .
      </p>
    </motion.div>
  );
}

export default StepTwo;
