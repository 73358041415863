import React, { useEffect } from "react";
import MetaDecorator from "../../components/MetaDecorator";

const metadata = {
  title: "بوابة صحتي | التحميل",
  description: "تحميل تطبيق صحتي 360",
};

function Sehty360() {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Redirect to Google Play for Android devices
    if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=co.health360.sehty360";
      return;
    }

    // Redirect to the App Store for iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/app/id6472179725";
      return;
    } else {
      // Optional: Add a fallback for other devices, or a message
      alert("رجاءً قم بزيارة هذه الصفحة من هاتفك لتحميل التطبيق");
    }
  }, []);
  return (
    <>
      <MetaDecorator
        title={metadata.title}
        description={metadata.description}
      />
      <h1 className="text-4xl">Refresh The page to be redirected</h1>
    </>
  );
}

export default Sehty360;
