import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import { LuMapPin } from "react-icons/lu";
import { Button } from "../../components/ui/button";
import LeafletControl from "./LeafletControl";
import { PiMapPinBold, PiMapPinFill } from "react-icons/pi";
delete L.Icon.Default.prototype._getIconUrl;

const Red_MARKER = `data:image/svg+xml;utf8,${encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#8b2727" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map-pin"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"/><circle cx="12" cy="10" r="3"/></svg>`
)}`;
const BoatIcon = L.icon({
  iconUrl: Red_MARKER,
  iconSize: [40, 40],
  iconAnchor: [12, 12],
  popupAnchor: [0, 0],
});

function LeafletMyPosition({ index, handlePositionChange }) {
  const [isLoading, setIsLoading] = useState(false);

  const map = useMapEvents({
    locationfound(e) {
      handlePositionChange(
        {
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        },
        index
      );
      map.flyTo(e.latlng, 18, { duration: 1 });
      setIsLoading(false);
    },
  });

  return (
    <LeafletControl position={"bottomright"}>
      <Button
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          map.locate();
        }}
        className="w-16 h-16 p-2 bg-white rounded-full shadow-md"
      >
        <PiMapPinFill size={17} />
      </Button>
    </LeafletControl>
  );
}

const LocationSelector = ({ index, position, handlePositionChange }) => {
  console.log(index);
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        // console.log(e.latlng);
        handlePositionChange(
          {
            latitude: e.latlng.lat,
            longitude: e.latlng.lng,
          },
          index
        );
        console.log(position);
        // onLocationSelected(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return position?.longitude != null ? (
      <Marker
        position={[position.latitude, position.longitude]}
        icon={BoatIcon}
      ></Marker>
    ) : null;
  };

  console.log(position);
  return (
    <MapContainer
      center={[
        position?.latitude != null ? position.latitude : 33.3353,
        position?.longitude != null ? position.longitude : 44.3673,
      ]}
      zoom={13}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LocationMarker />
      <LeafletMyPosition
        index={index}
        handlePositionChange={handlePositionChange}
      />
    </MapContainer>
  );
};

export default LocationSelector;
