import * as z from "zod";

const phoneRegex = new RegExp(/^(\d{4}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

export default z.object({
  name: z
    .string({
      required_error: "الاسم مطلوب",
    })
    .min(3, { message: "يجب ان يكون الاسم 3 احرف على الاقل." })
    .max(255),
  category_id: z.number(),
  gender_id: z.number().nullable().optional(),
  specialities: z.array(z.number()).optional(),
  phone_numbers: z.array(
    z
      .string({
        required_error: "الرقم مطلوب",
      })
      .regex(phoneRegex, "!رقم الهاتف غير صالح")
      .min(11, {
        message: "يجب ان لا يقل عن 11 رقما.",
      })
  ),
  details: z.string({
    required_error: "التفاصيل مطلوبة",
  }),
  infos: z.array(
    z.object({
      province_id: z.number({ required_error: "المحافظة مطلوبة" }),
      address: z.string({
        required_error: "العنوان مطلوب",
      }),
      longitude: z.number().optional(),
      latitude: z.number().optional(),
      days: z.array(z.number()).optional(),
      from_time: z.string().nullable().optional(),
      to_time: z.string().nullable().optional(),
    })
  ),
});
