import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";
import { Button } from "../components/ui/button";
import { MdOutlineCreditCardOff } from "react-icons/md";
import Register from "../pages/Register";
import Sehty360 from "../pages/Sehty360";

function RootBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <div>This page doesn't exist!</div>;
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }
    if (error.status === 500) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }

  return (
    <div className="text-center">
      <header className="h-[100dvh] bg-slate-50 flex flex-col items-center justify-center gap-4 text-blue-950 text-2xl">
        <div className="error-icon">
          <MdOutlineCreditCardOff size={48} color="red" />
        </div>
        <h1 className="text-4xl font-bold">لقد حدث خطأ</h1>
        <div className="buttons">
          <Button variant="blue" onClick={() => window.location.reload()}>
            حاول مجددا
          </Button>
        </div>
        <p>حاول التحميل مجددا، أو اتصل بفريق الدعم الفني للمساعدة.</p>
      </header>
    </div>
  );
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<RootBoundary />}>
      <Route index element={<Register />} />
      {/* <Route path="*" element={<NotFoundPage />} /> */}
      <Route path="sehty360" element={<Sehty360 />} />
      {/* <Route></Route>
        <Route></Route> */}
    </Route>
  )
);
