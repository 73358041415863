import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";

function Loading() {
  return (
    <Player
      autoplay
      loop
      src="/images/loading.json"
      style={{ height: "300px", width: "300px" }}
    ></Player>
  );
}

export default Loading;
