import React, { useEffect, useState, useCallback } from "react";
import MetaDecorator from "../../components/MetaDecorator";
import registerSchema from "./registerSchema";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@components/ui/form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "react-international-phone/style.css";
import BGImage from "../../assets/images/bg.png";
import { apiPostSehty, apiPostSehtyImage } from "../../services/sehty";
import imageCompressor from "../../utils/imageCompressor";
import { useDropzone } from "react-dropzone";
import { AnimatePresence } from "framer-motion";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";

export const metadata = {
  title: "بوابة صحتي | التسجيل",
  description: "التسجيل في صحتي",
};

const Register = () => {
  const [step, setStep] = useState(1);
  const nextStep = () => {
    setStep(step + 1);
  };

  // 1. Define your form.
  const form = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: "",
      category_id: null,
      gender_id: null,
      specialities: [],
      phone_numbers: [""],
      details: "",
      infos: [
        {
          province_id: null,
          address: null,
          longitude: null,
          latitude: null,
          days: [],
          from_time: null,
          to_time: null,
        },
      ],
    },
  });
  const {
    fields: phoneNumbers,
    append: appendPhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control: form.control,
    name: "phone_numbers",
  });
  const {
    fields: infos,
    append: appendInfo,
    remove: removeInfo,
  } = useFieldArray({
    control: form.control,
    name: "infos",
  });
  const [file, setFile] = useState(null);
  const onDrop = useCallback((file) => {
    // Do something with the files
    setFile(
      Object.assign(file[0], {
        preview: URL.createObjectURL(file[0]),
      })
    );
  }, []);
  // Clean up the URLs when the component unmounts
  useEffect(() => {
    return () => (file) => URL.revokeObjectURL(file.preview);
  }, [file]);
  useEffect(() => {
    appendPhoneNumber("");
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const uploadFiles = async (id) => {
    // Check if there are actual file objects in the files array
    const hasFilesToUpload = file instanceof File;

    // If no actual files to upload, simply return or perform other actions as needed
    if (!hasFilesToUpload) {
      console.log("لا توجد ملفات.");
      return;
    }

    // If there are files, proceed with the upload process
    const formData = new FormData();
    // Append only file objects to formData
    if (file instanceof File) {
      const compressedFile = await imageCompressor(file);
      formData.append("image", compressedFile.image, compressedFile.name);
    }

    try {
      const response = await apiPostSehtyImage(id, formData);
      console.log("تم الرفع بنجاح:", response.data);
    } catch (error) {
      console.error("فشلت العملية:", error);
    }
  };

  const { isPending, mutate } = useMutation({
    mutationKey: "postUsers",
    mutationFn: async (values) => {
      let data = await apiPostSehty(values);
      await uploadFiles(data.id);
    },
    onSuccess: (data) => {
      // Handle successful submission here
      nextStep();
    },
    onError: (error) => {
      // Handle error here
      toast.error("حدث خطأ: " + error.message);
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values, e) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    e.preventDefault();
    console.log(values);
    mutate(values);
    // const { id } = await apiPostPutProducts(product_param, values);
    // uploadFiles(id);
  }
  // Define the transition details
  const transition = {
    duration: 0.4, // 300ms
    ease: "easeInOut",
  };

  // Define the variants for the motion component
  const formVariants = {
    initial: {
      opacity: 0,
      scale: 0,
      transition,
    },
    enter: {
      opacity: 1,
      scale: 1,
      transition,
    },
    exit: {
      opacity: 0,
      scale: 0,
      transition,
    },
  };

  const handlePositionChange = (e, index) => {
    form.setValue(`infos.0.latitude`, e.latitude);
    form.setValue(`infos.0.longitude`, e.longitude);
  };
  return (
    <div
      style={{
        width: "100%",
        background: `url(${BGImage}) no-repeat center center fixed`,
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <MetaDecorator
        title={metadata.title}
        description={metadata.description}
      />
      <div className="md:container relative flex-col items-center justify-center lg:max-w-none lg:px-0">
        <div className="mx-auto flex w-full font-bold flex-col justify-center lg:max-w-lg">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <AnimatePresence mode="wait">
                {step === 1 && (
                  <Step1
                    form={form}
                    formVariants={formVariants}
                    nextStep={nextStep}
                  />
                )}
                {step === 2 && (
                  <Step2
                    form={form}
                    formVariants={formVariants}
                    nextStep={nextStep}
                    phoneNumbers={phoneNumbers}
                    appendPhoneNumber={appendPhoneNumber}
                    removePhoneNumber={removePhoneNumber}
                    infos={infos}
                    appendInfo={appendInfo}
                    removeInfo={removeInfo}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                    file={file}
                    setFile={setFile}
                    handlePositionChange={handlePositionChange}
                    isPending={isPending}
                  />
                )}
                {step === 3 && <Step3 formVariants={formVariants} />}
              </AnimatePresence>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
