import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { motion } from "framer-motion";

function StepOne({ form, formVariants, nextStep }) {
  return (
    <motion.div
      variants={formVariants}
      initial="initial"
      animate="enter"
      exit="exit"
      key="emailForm"
    >
      <Card className="bg-inherit shadow-none border-none mt-16 lg:mt-28">
        <CardHeader className="p-6 pb-2 space-y-1 text-center">
          <CardTitle className="text-2xl">سجل في برنامج صحتي مجانًا</CardTitle>
          <h4 className="pt-5 pb-2 text-primary-8">سجل ك...</h4>
        </CardHeader>
        <CardContent className="grid gap-4">
          <div className="text-justify">
            <button
              className="bg-white bg-opacity-60 px-6 py-5 rounded-xl mx-2 hover:bg-opacity-30 transition ease-out duration-300 focus:outline-none"
              onClick={() => {
                form.setValue("category_id", 1);
                nextStep();
              }}
              type="button"
            >
              <h2 className="text-xl font-semibold mb-5">طبيب</h2>
              <p className="text-lg font-medium mb-7">
                اضف عيادتك الى برنامج صحتي مجانًا, سوف يتمكن مستخدمي برنامج صحتي
                من الاطلاع عليها وحجز موعد من خلال البرنامج.
              </p>
            </button>
            <h2 className="py-5 text-primary-8 text-center">او</h2>
            <button
              className="bg-white bg-opacity-60 px-6 py-5 rounded-xl mx-2 hover:bg-opacity-30 transition ease-out duration-300 focus:outline-none"
              type="button"
              onClick={() => {
                form.setValue("category_id", 2);
                nextStep();
              }}
            >
              <h2 className="text-xl font-semibold mb-5">صيدلي</h2>
              <p className="text-lg font-medium mb-7">
                اضف صيدليتك الى برنامج صحتي مجانًا, سوف يتمكن مستخدمي برنامج
                صحتي من الاطلاع عليها والتواصل معك من خلال البرنامج.
              </p>
            </button>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
}

export default StepOne;
