import Compressor from "compressorjs";

export default function imageCompressor(file) {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();

    fr.onload = async function (event) {
      let openedFile = await (await fetch(event.target.result)).blob();
      if (["image/png", "image/jpeg", "image/jpg"].includes(openedFile.type)) {
        new Compressor(openedFile, {
          quality: 0.6,

          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            console.log(result);
            resolve({
              image: result,
              name: file.name,
            });
          },
          error(err) {
            console.log(err.message);
          },
        });
      } else {
        resolve({
          image: openedFile,
          name: file.name,
        });
      }
    };
    fr.readAsDataURL(file);
  });
}
