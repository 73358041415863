export const provinceNames = [
  { id: 3, name: "بغداد" },
  { id: 1, name: "الانبار" },
  { id: 2, name: "بابل" },
  { id: 4, name: "البصرة" },
  { id: 5, name: "ذي قار" },
  { id: 6, name: "القادسية" },
  { id: 7, name: "ديالى" },
  { id: 8, name: "دهوك" },
  { id: 9, name: "اربيل" },
  { id: 10, name: "كربلاء" },
  { id: 11, name: "كركوك" },
  { id: 12, name: "ميسان" },
  { id: 13, name: "المثنى" },
  { id: 14, name: "النجف" },
  { id: 15, name: "نينوى" },
  { id: 16, name: "صلاح الدين" },
  { id: 17, name: "سليمانية" },
  { id: 18, name: "واسط" },
];

export const genders = {
  1: "انثى",
  2: "ذكر",
};

export const days = [
  { id: 1, name: "الاحد" },
  { id: 2, name: "الاثنين" },
  { id: 3, name: "الثلاثاء" },
  { id: 4, name: "الاربعاء" },
  { id: 5, name: "الخميس" },
  { id: 6, name: "الجمعة" },
  { id: 7, name: "السبت" },
];

export const specialityNames = [
  { id: 1, name: "حساسية ومناعة" },
  { id: 2, name: "تخدير" },
  { id: 4, name: "أمراض جلدية" },
  { id: 5, name: "طب الطوارئ" },
  { id: 6, name: "طب الأسرة" },
  { id: 7, name: "أمراض الجهاز الهضمي" },
  { id: 8, name: "جراحة عامة" },
  { id: 9, name: "طب الشيخوخة" },
  { id: 10, name: "أمراض نساء" },
  { id: 11, name: "أمراض دم" },
  { id: 12, name: "أمراض معدية" },
  { id: 13, name: "طب باطني" },
  { id: 14, name: "أمراض كلى" },
  { id: 15, name: "أمراض أعصاب" },
  { id: 16, name: "توليد" },
  { id: 17, name: "أمراض سرطان" },
  { id: 18, name: "طب عيون" },
  { id: 19, name: "جراحة العظام والكسور" },
  { id: 20, name: "أمراض أذن وأنف وحنجرة" },
  { id: 21, name: "علم الأمراض" },
  { id: 22, name: "طب أطفال" },
  { id: 23, name: "جراحة تجميل" },
  { id: 24, name: "طب نفسي" },
  { id: 25, name: "أمراض الرئة" },
  { id: 26, name: "الاشعة والمفاصل والسونار" },
  { id: 27, name: "أمراض المفاصل والروماتيزم" },
  { id: 28, name: "أمراض المسالك بولية وعقم الرجال" },
  { id: 29, name: "جراحة قلب" },
  { id: 30, name: "جراحة أعصاب" },
  { id: 31, name: "جراحة الفم والاسنان" },
  { id: 32, name: "جراحة الاطفال" },
  { id: 33, name: "طب التغذية" },
  { id: 34, name: "جراحة السمنة" },
];

export const categories = {
  1: "الاطباء",
  2: "الصيدليات",
};
