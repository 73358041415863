import { axiosInstance } from "../lib";

const getData = async (path) => {
  try {
    const response = await axiosInstance.get(path);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw new Error(error.message);
  }
};

const getResponse = async (path) => {
  try {
    const response = await axiosInstance.get(path);
    return response;
  } catch (error) {
    console.log({ error });
    return error.response;
  }
};

const postData = async (path, body) => {
  try {
    const response = await axiosInstance.post(path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const patchData = async (path, body) => {
  try {
    const response = await axiosInstance.patch(path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const patchDataWithQuery = async (path) => {
  try {
    const response = await axiosInstance.patch(path);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const getFiles = async (path) => {
  try {
    const response = await axiosInstance.get(path, {
      headers: {
        CacheControl: "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const getFile = async (path) => {
  try {
    const response = await axiosInstance.get(path, {
      headers: {
        CacheControl: "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
      responseType: "blob",
    });
    if (response.status != 200) {
      throw new Error(response);
    }
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const getURLFile = async (path) => {
  try {
    const response = await axiosInstance.get(path, {
      responseType: "blob",
    });
    if (response.status != 200) {
      throw new Error(response);
    }
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const formDataService = async (path, body, method) => {
  try {
    const response = await axiosInstance[method.toLowerCase()](path, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const patchOrPost = async (path, body, method) => {
  try {
    const response = await axiosInstance[method.toLowerCase()](path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const postOrPut = async (path, body, method) => {
  try {
    const response = await axiosInstance[method.toLowerCase()](path, body);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const destroyData = async (path) => {
  try {
    const response = await axiosInstance.delete(path);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data.message;
  }
};

const service = {
  getData,
  getResponse,
  getFiles,
  getFile,
  postData,
  patchData,
  patchDataWithQuery,
  formDataService,
  patchOrPost,
  postOrPut,
  destroyData,
  getURLFile,
};

export default service;
