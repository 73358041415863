import React from "react";
import { Helmet } from "react-helmet";

const MetaDecorator = ({ title, description, imageUrl }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description ?? ""} />
    <meta property="og:title" content={title} />
    <meta property="og:site_name" content={description} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:url" content={window.location.href} />
  </Helmet>
);

export default MetaDecorator;
